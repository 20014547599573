export default {
 
  methods: {
    dna2prot(x, start, end, first) {
      if (x < start || x > end) return 0;
      return Math.floor((x - start) / 3 + first);
    },
    prot2dna(p, start, end, first) {
      if (p < first || p > first + (end - start) / 3) return 0;
      return start + (p - first) * 3;
    },
    averageOverWindow(data, window) {
      let l = data.length;
      let result = new Array(l);
      result.fill(0);
      // number of residues before position
      let before = Math.floor(window / 2);
      let after = Math.ceil(window / 2) - 1;
      // this.log(`Before ${before}, after ${after}`);

      //first and last mutations in the range
      let start = 0,
        end = 0;

      let rollingSum = 0;
      let rollingAvg = 0;
      ////initial sum
      for (let p = 0; p <= after; p++) {
        rollingSum += data[p];
      }

      for (let p = 0; p < l; p++) {
        start = Math.max(p - before, 0);
        end = Math.min(p + after, l - 1);
        rollingSum += data[end]; //last;
        rollingSum -= data[start]; //first;
        rollingAvg = (rollingSum / (end - start)).toPrecision(4);
        result[p] = rollingAvg;
      }
      return result;
    },

    /**
     * 
     * @returns Main url fff the data api 
     */
    getDataApiUrl() {
      return "https://data.pathogens3d.org/"
    },
    dataUrlFromParams(params) {
      let fam = params.family || 'bunyaviruses';
      let sp = params.species || 'lasv';
      let sg = params.segment || 's';
      return `${fam.toLowerCase()}/${sp.toLowerCase()}/${sg.toLowerCase()}/`;
    },
    // /**
    //  * 
    //  * @returns Returns specie-specific url
    //  */
    // getUrlPrefix() {
    //   return '';
    //   // return "bunyaviruses/"
    // },
    /**
     * 
     * @returns Returns base URL for data queries
     */
    getBaseUrl() {
      return this.getDataApiUrl() ;//+ this.getUrlPrefix() ;//+ this.dataUrl;
    }
  }
}